import type { ElementType } from "react";
import { useTranslation } from "react-i18next";
import { cn } from "~/util/cn";
import { type ZodError } from "zod";
import React from "react";

export interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string | React.ReactNode;
  error?: string;
  zodError?: ZodError | null;
  tag?: ElementType;
  inputClassName?: string;
}

const Input = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      label,
      error,
      zodError,
      tag = "input",
      className,
      inputClassName,
      ...props
    },
    ref,
  ) => {
    const Tag = tag;
    const { t } = useTranslation();

    if (!error && zodError?.issues?.length) {
      error = zodError.issues.find((issue) =>
        issue.path.includes(props.name as string),
      )?.message;

      // translate error message
      error = typeof error === "string" ? t(error) : error;
    }

    return (
      <div
        className={cn("font-sans-alt mb-3 leading-5 text-gray-800", className)}
      >
        <label
          htmlFor={props.name}
          className={cn(
            props.type === "checkbox" &&
              "flex cursor-pointer flex-row-reverse items-center justify-end gap-2",
          )}
        >
          <span
            className={cn("mb-1 text-sm", props.type === "checkbox" && "mb-0")}
          >
            {label}
            {props.required && (
              <span className="ml-[0.5px] text-red-500" aria-hidden="true">
                *
              </span>
            )}
          </span>
          <div className={cn("relative")}>
            <Tag
              id={props.name}
              ref={ref}
              {...props}
              className={cn(
                "shadow-input block w-full rounded-md border border-gray-100 bg-white p-3 align-middle transition",
                "focus:shadow-input-focus focus:border-blu-300 focus:outline-0",
                props.type === "checkbox" && "w-auto cursor-pointer",
                tag === "textarea" && "resize-x-none h-32",
                tag === "select" && "appearance-none pr-10",
                error ? "mb-1 border-red-300" : "",
                props.readOnly &&
                  "user-select-none cursor-not-allowed border-gray-200 bg-gray-100 text-gray-500",
                inputClassName,
              )}
            >
              {props.children}
            </Tag>
            {tag === "select" && (
              <div className="i-solar:alt-arrow-down-linear absolute right-0 top-0 m-[0.75rem] text-lg text-gray-500" />
            )}
          </div>
        </label>
        {error && (
          <span className="mb-1 block text-sm text-red-500">{error}</span>
        )}
      </div>
    );
  },
);

Input.displayName = "Input";

export default Input;
